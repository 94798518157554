import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const ai = {
  namespaced: true,
  state: {
    currentType: 'smartSearch',
    smartSearch: {
      criteria: {
        device: -1,
        date: {
          startDateTime: null,
          endDateTime: null
        },
        peopleDetection: false,
        peopleDetectionArea: null,
        sensitivity: 7,
        timeFilter: {
          duration: '500',
          interval: '1500'
        },
        coordinates: [],
        polygon: [],
        bitmap: [],
        searchKey: (new Date()).getTime().toString()
      },
      result: [],
      export: [],
      keyword: '',
      playingResult: null,
      isPlayingAll: false,
      isFinishedPlaying: false,
      hasDateTimeError: false,
      currentPageIndex: 0,
      activeVideoId: -1,
      playList: [],
      MAX_PAGE_COUNT: 12,
    },
    exportDataList: [],
    status: 'initial', // initial,searched,failed,loading
    exportStatus: 'NORMAL'
  },
  getters,
  actions,
  mutations,
};

export default ai;
